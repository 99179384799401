.sapp-color-text {
  color: #7e8299 !important;
}

.sapp-px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.sapp-pt-24px {
  padding-top: 24px;
}

.sapp-pb-24px {
  padding-bottom: 24px;
}

.sapp-lh-14px {
  line-height: 14px;
}

.sapp-px-175px {
  padding-left: 175px;
  padding-right: 175px;
}

.sapp-h-62px {
  height: 62px;
}

.sapp-mt-24px {
  margin-top: 24px;
}

.sapp-border-entrance-test {
  border-radius: 6px 6px 0px 0px;
}

.sapp-radius-bottom {
  border-radius: 0px 0px 6px 6px;
}

.border-radius-6px {
  border-radius: 6px;
}

.end-15px {
  right: 15px !important;
}

.sapp-hook-form-text_skeleton {
  min-height: 40px !important;
}

.sapp-input-group {
  display: grid;
  grid-template-columns: min-content auto;

  input {
    width: 100% !important;
    max-height: 40px;
  }
}

.sapp-input-group-text {
  max-height: 45px;
  min-width: 45px;
}

.sapp-h-48px {
  .sapp-form-control-custom {
    padding-top: 0.845rem !important;
    padding-bottom: 0.845rem !important;
  }
}

.disabled-background {
  background-color: #f5f5f5 !important;
}

.sapp-h-48px {
  .sapp-input-group-text {
    max-height: 48px;
  }

  .sapp-input-group input {
    max-height: 48px;
  }
}

.sapp-h-70px {
  .sapp-form-control-custom {
    height: 70px !important;
  }
}

.sapp-h-70px {
  .sapp-input-group-text {
    max-height: 70px;
  }

  .sapp-input-group input {
    max-height: 70px;
  }
}

.sapp-h-70px {
  .form-control {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    border-radius: 0px !important;
    height: 70px !important;
  }
}

.sapp-post-fix-text-field {
  color: #5e6278;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.sapp-h-50px {
  .sapp-form-control-custom {
    height: 50px !important;
  }
  .btn {
    .indicator-label {
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.sapp-h-48px {
  .sapp-input-group-text {
    max-height: 50px;
  }

  .sapp-input-group input {
    max-height: 50px;
  }
}

.input-group-text {
  color: var(--bs-primary-border) !important;
  font-size: 14px !important;
}

.input-group-box-postfix {
  border-radius: 0px 6px 6px 0px;
}

.input-group-text-input-postfix {
  border-radius: 6px 0px 0px 6px;
}

.sapp-form-control.sapp-border-none {
  border: none !important;
}

.sapp-border-input {
  border-bottom: 1px solid #f1f1f4;
}

.sapp-h-65px {
  height: 60px !important;
}

.sapp-form-control:focus {
  border: 1px solid var(--bs-primary) !important;
}

.sapp-form-control {
  color: var(--bs-gray-800) !important;
  line-height: 14px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
}

.btn__password-eye {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  z-index: 1;
}

.btn__password-eye::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(158, 158, 158, 0.1);
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease;
  z-index: -1;
}

.btn__password-eye:hover::before {
  width: 35px;
  height: 35px;
}

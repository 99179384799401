@import '../../../../_metronic/assets/sass/init';

.descriptiton {
  margin-bottom: 24px;
}

.ttl {
  margin-bottom: 32px;
}

.w-40p {
  min-width: 250px;
}

.border-content {
  position: relative;
  margin-bottom: 24px;
  height: 1px;
}

.border-content:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0; /* Đặt chiều cao thành 0 để chỉ có đường biên dưới */
  border-bottom: 1px solid transparent; /* Đặt border-bottom là solid và trong suốt */
  border-image: repeating-linear-gradient(
      to right,
      transparent 0,
      transparent 10px,
      $primary-placeholder 10px,
      $primary-placeholder 20px
    )
    1;
}

.description-text {
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  color: $primary-placeholder;
}

.description-value {
  font-weight: 600;
  line-height: 24px;
  color: $dark-describe-800;
}

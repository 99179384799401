.sapp-upload-file {
  &-progress {
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    padding: 15px 20px;
    border-radius: 6px;
    margin-top: 20px;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .ant-progress {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &_icon {
      margin-right: 1.2rem;
    }

    .ant-progress-outer {
      order: 2;
    }

    .ant-progress-text {
      margin-right: 2em;
      margin-left: 1.2rem;
      order: 1;
    }
  }
}

.sapp-upload-file-content {
  position: relative;

  .sapp-upload-file-name {
    position: absolute;
    top: -4px;
    left: 0;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sapp-resource-setting {
  &-create {
    .sapp-list-question-added-container {
      max-height: 60% !important;
    }
  }
  &-update {
    .sapp-list-question-added-container {
      max-height: 70% !important;
    }
  }
}

.sapp-min-width-285 {
  min-width: 285px;
}

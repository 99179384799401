.sapp-btn--danger {
  background-color: #f1416c;
  font-size: 14px;
  color: #ffffff;
  line-height: 16px;
}

.sapp-btn--danger:hover {
  background-color: #d9214e;
  color: #ffffff;
}

.sapp-btn--danger:active {
  background-color: #d9214e !important;
  color: #ffffff !important;
}

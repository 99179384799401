.sapp-label-checkbox {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #404041;
}

.sapp-checkbox input[type='checkbox']:checked {
  border-color: transparent;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.sapp-checkbox input[type='checkbox'] {
  border: 1.5px solid #404041;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  --tw-shadow: 0 0 #0000;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.sapp-checkbox input[type='checkbox'].sapp-error-checkbox:checked {
  background-color: #b90e0a;
  opacity: 1;
}

.sapp-checkbox input[type='checkbox'].sapp-success-checkbox:checked {
  background-color: #397839;
  opacity: 1;
}

.sapp-checkbox input[type='checkbox'].sapp-default-checkbox:checked {
  background-color: #fff;
  opacity: 1;
}

.sapp-tab-btn {
  font-weight: 400;
  font-size: 18px;
  font-family: Inter;
  line-height: 16px;
  color: var(--bs-primary-border);
  padding-bottom: 18px !important;
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.sapp-tab--first {
  padding-left: 26px !important;
  padding-right: 26px !important;
  font-size: 18px;
  line-height: 16px;
  color: #99a1b7;
}

.sapp-text-active {
  font-weight: 500;
  color: var(--bs-gray-800) !important;
  border-bottom: 1px solid var(--bs-primary) !important;
  font-size: 18px;
  line-height: 16px;
  padding-bottom: 18px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.sapp-inputname-wrapper {
  // padding: 27px 0 27px 0;
  border-bottom: 1px dashed #e1e3ea;
}

.sapp-doc-input-wrapper {
  border-top: 1px dashed #e1e3ea;
}

.sapp-input-tab {
  min-height: 48px;
}

.sapp-addbtn-wrapper {
  border: 1px solid #8f8f8f;
  border-radius: 9px;
}

.sapp-footer-create-doc {
  padding: 2rem 0;
  border-top: 1px solid #f4f4f4;
}

.sapp-custom-card {
  // min-height: 48px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: var(--bs-gray-100);
}

.sapp-tab-content-wrapper {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

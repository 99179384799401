.sapp-btn-action-cell {
  &_svg {
    fill: var(--bs-gray-600);
  }

  &:hover &_svg {
    fill: var(--bs-primary);
  }
}

.menu-sub-dropdown {
  margin-top: 1.1rem !important;
}

.sapp-btn--primary {
  background-color: var(--bs-primary) !important;
  font-size: 14px;
  color: var(--bs-white) !important;
  font-weight: 500;
  line-height: 16px;
}

.sapp-btn--primary:hover {
  background-color: #f6c000 !important;
  color: var(--bs-white);
}

.sapp-btn--primary:active {
  background-color: #f6c000 !important;
  color: var(--bs-white) !important;
}

.sapp-btn--primary i {
  color: var(--bs-white);
}

.sapp-lh-16 {
  line-height: 16px;
}

.sapp-list-staff {
  .search-input {
    font-weight: 500 !important;
  }

  .sapp-font-import-student {
    font-weight: 500 !important;
  }

  .sapp-selected-user {
    .btn.btn-danger {
      font-weight: 500 !important;
      font-size: 13px !important;
      background-color: var(--bs-danger) !important;
    }

    .fw-bolder {
      font-weight: 500 !important;
      color: var(--bs-danger) !important;
    }
  }
}

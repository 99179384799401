.mock-test-type {
  &_content {
    &:hover {
      & > .mock-test-type_name {
        color: var(--bs-primary);
      }

      & > .mock-test-type_img {
        display: none;
      }

      & > .mock-test-type_img__hovered {
        display: block;
      }
    }
  }

  &_img__hovered {
    display: none;
  }

  &_name {
    text-align: center;
    color: #99a1b7;
    line-height: 20px;
    font-size: 13px;
  }
}
.sapp-table-container {
  max-width: 1570px;
  margin: auto;
}

.sapp-h-60vh {
  height: 60vh;
}

.sapp-working-guide {
  background-color: #f2f7ff;
  .card-body {
    padding-left: 24px;
    padding-right: 24px;
  }
  &_text {
    color: #414b5b;
  }
}

.sapp-bg-popup--profile {
  background-color: var(--bs-menu-dropdown-bg-color) !important;
}

.menu-active {
  color: var(--bs-primary) !important;
}

.menu-active-bg-primary .menu-item .menu-link.active .menu-icon,
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon,
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon i {
  color: #fff !important;
}

.app-sidebar-secondary .menu .menu-item.show > .menu-link .menu-icon,
.app-sidebar-secondary .menu .menu-item.show > .menu-link .menu-icon .svg-icon,
.app-sidebar-secondary .menu .menu-item.show > .menu-link .menu-icon i {
  color: #fff !important;
}

.app-sidebar-secondary .menu .menu-item.show > .menu-link .menu-title {
  color: #fff !important;
}

.menu-arrow-gray-500 .menu-item .menu-link .sapp-icon-sidebarright:after {
  background-color: #fff;
}

.menu-item .menu-link .menu-arrow.sapp-icon-sidebarleft:after {
  background-color: var(--bs-primary) !important;
}

.sapp-group-hover--sidebarleft {
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    .menu-icon .ki-outline {
      color: var(--bs-primary) !important;
    }

    .menu-title {
      color: var(--bs-primary) !important;
    }

    .menu-arrow::after {
      background-color: var(--bs-primary) !important;
    }
  }
}

.sapp-group-hover--sidebarright {
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    .menu-icon .ki-outline {
      color: #fff !important;
    }

    .menu-title {
      color: #fff !important;
    }

    .menu-arrow::after {
      background-color: #fff !important;
    }
  }
}

.menu-item.show > .menu-link .menu-icon i {
  color: var(--bs-primary) !important;
}

.app-sidebar-secondary .menu-content {
  color: var(--bs-gray-600);
}

.sapp-create-noti_group {
  border: 1px solid #e1e3ea;
  gap: 12px;
  padding: 5px;
  flex-wrap: wrap;
  min-height: 48px;
  border-radius: 0.65rem;
  &_tags {
    flex: 1;
    column-gap: 12px;
    row-gap: 6px;
    flex-wrap: wrap;
  }
  &_items {
    font-size: 13px;
    font-weight: 500;
    // line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #7e8299;

    top: 5px;
    left: 5px;
    padding: 13px 12px 13px 12px;
    border-radius: 6px;
    background: #f1f1f2;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    max-height: 38px;
    overflow: hidden;
    max-width: 500px;
  }
  &_text {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_icon {
    cursor: pointer;
  }
}

.sapp-dots-end {
  right: 0 !important;
  top: 51%;
  transform: translateY(-50%);
}

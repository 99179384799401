@import '../../../styles/index.scss';

.sapp-datetime.ant-calendar-picker-input.ant-input:hover {
  border-color: var(--bs-gray-300) !important;
  outline: 0 !important;
}

.sapp-datetime:hover {
  border-color: var(--bs-gray-300) !important;
}

.sapp-datetime.ant-picker-focused {
  border-color: var(--bs-gray-300) !important;
  outline: 0 !important;
}

.sapp-datetime .ant-picker-active-bar {
  background-color: var(--bs-primary) !important;
}

.ant-picker-today-btn {
  color: var(--bs-primary) !important;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: var(--bs-primary) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--bs-primary) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--bs-primary) !important;
}

.ant-picker-input > input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: $primary-placeholder !important;
}

.sapp-fs .ant-picker-input > input::placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color: var(--bs-gray-500) !important;
}

.ant-picker {
  border: 1px solid $primary-border;
  border-radius: 6px;
}

.ant-picker .ant-picker-input > input {
  font-weight: 500 !important;
  font-family: Inter, Helvetica, 'sans-serif' !important;
  color: var(--bs-gray-700) !important;
  line-height: 21px !important;
  font-size: 14px !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: var(--bs-primary) !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #fffaf0 !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background-color: #fffaf0 !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #fffaf0 !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--bs-primary) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  .ant-picker-cell-inner {
    color: var(--bs-primary) !important;
  }
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
  &::after {
    border-color: var(--bs-primary) !important;
  }
}
.ant-picker-range .ant-picker-active-bar {
  background: var(--bs-primary);
}
.ant-picker-focused.ant-picker {
  border-color: none;
  box-shadow: none;
}

.createDateRangePicker.ant-picker-focused {
  border-color: var(--bs-primary);
}

.ant-picker-clear {
  font-size: 16px;
}

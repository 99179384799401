.sapp-mw-220px {
  max-width: 220px;
}

.sapp-height-45px {
  height: 45px;
}

.sapp-mw-215px {
  max-width: 215px;
}
.sapp-tag-form-custom {
  min-height: 45px;
}
.sapp-checkbox-text-custom {
  color: #141414 !important;
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}

.sapp-h-150px {
  height: 150px !important;
}

.sapp-mt-32px {
  margin-top: 32px;
}

.sapp-mb-24px {
  margin-bottom: 24px;
}

.sapp-fs-20px {
  font-size: 20px;
}

.sapp-mw-950px {
  max-width: 950px !important;
}

.sapp-modal--header {
  padding-top: 27px;
  padding-bottom: 24px;
  padding-left: 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
}

.sapp-modal--content {
  padding: 0 32px;
}

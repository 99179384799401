.sapp-badge {
  border: 1px solid black;
  border-radius: 3px;
  padding: 3px 8px;

  &-inprogress {
    border-color: #ffb800;
    color: #ffb800;
    background-color: #ffb8001a;
  }

  &-success {
    border-color: #009966;
    color: #009966;
    background-color: #0099661a;
  }

  &-cancel {
    border-color: #f8285a;
    color: #f8285a;
    background-color: #f8285a1a;
  }
}

.mock-test {
  &_type {
    .hook-form-radio-group {
      justify-content: start !important;
      column-gap: 35px;
      row-gap: 15px;
    }
  }

  &_grading-preference,
  &_pass-point,
  &_attempt {
    .hook-form-radio-group {
      justify-content: start !important;

      & > div:first-child {
        width: 30%;
        min-width: 200px;
      }
    }
  }
}

.list-question {
  p {
    margin-bottom: 0;
  }
}

.sapp-post-fix {
  color: var(--gray-gray-700, #5e6278);
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
}
.sapp-search-custom {
  &::placeholder {
    font-weight: 500;
    font-size: 13px;
    color: #99a1b7;
  }
  &:focus {
    background-color: var(--bs-gray-200);
  }
}

.sapp-w-180 {
  width: 180px;
}

.sapp-input-percent-total {
  &:disabled {
    background-color: #f9f9f9 !important;
  }

  & + div .ant-typography small {
    font-size: 14px !important;
    line-height: 16.9px !important;
    color: #d35563;
  }
}

.sapp-input-percent-child {
  height: 38px;

  &:disabled {
    background-color: #f9f9f9 !important;
  }

  & + .input-group-text {
    height: 38px;
  }
}

.sapp-w-95px {
  width: 95px;
}

.sapp-align-input {
  text-align: end;
  width: 70px !important;
}

.sapp {
  &-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
  }

  &-icon-yellow {
    color: #ffb800;
    background-color: #fef7e5;
  }

  &-description {
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #7c87aa;
  }

  &-hotline {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    text-align: center;
    color: #7c87aa;
  }

  &-ticket {
    &-top,
    &-middle,
    &-bottom {
      background-color: #f5f5f5;
    }

    &-top {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #e8ebf1;
      border-radius: 12px 12px 0 0;
      padding: 24px 24px 12px 24px;
    }

    &-middle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }

    &-bottom {
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: #e8ebf1;
      border-radius: 0 0 12px 12px;
      padding: 12px 24px 24px 24px;
    }

    &-title,
    &-content,
    &-total {
      border-radius: 12px;
      padding: 16px;
      background-color: white;
    }

    &-title {
      margin-bottom: 12px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > div > div:first-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 16.8px;
        color: #7c87aa;
      }

      & > div > div:last-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: end;
        color: #253058;
      }
    }

    &-dash {
      width: 100%;
      margin: 0 12px;
    }

    &-semicircle {
      height: 40px;
      width: 20px;
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: #e8ebf1;
      border-radius: 9999px 0 0 9999px;
      background-color: white;

      &:first-child {
        transform: rotate(180deg);
      }
    }

    &-total {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div > div:first-child {
        margin-bottom: 9px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16.8px;
        color: #7c87aa;
      }

      & > div > div:last-child {
        font-size: 24px;
        font-weight: 700;
        line-height: 25.2px;
        color: #ffb800;
      }
    }

    &-payment {
      display: flex;
      background-color: #f5f5f5;

      & > div:first-child {
        margin-right: 8px;
      }
    }
  }

  &-w {
    &-430 {
      width: 430px;
    }

    &-340 {
      width: 340px;
    }
  }

  &-mb {
    &-6 {
      margin-bottom: 1.5rem;
    }
  }

  &-py {
    &-20 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
  }

  &-px {
    &-40 {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
  }

  &-gap {
    &-3 {
      gap: 0.75rem;
    }

    &-6 {
      gap: 1.5rem;
    }
  }

  &-bg {
    &-white {
      background-color: #fff;
    }
  }
}

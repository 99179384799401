.sapp-search-header {
  background-color: #ffffff;
}

.sapp-search-case-study {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  line-height: 12px;

  &:focus {
    background-color: #f9f9f9;
  }
}

.sapp-search-input {
  min-width: 268px;
}

.sapp-proress-bar-wrapper {
  padding: 12px 24px;
}

.sapp-btn-color {
  color: var(--bs-light-inverse);
  background-color: var(--bs-light);
}

.sapp-blank-content {
  min-height: 73vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sapp-blank-content-title {
    color: var(--text-color-2nd, #a1a1a1);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
  }
}

.sapp-popup-text,
.sapp-popup-text .menu-item .menu-link {
  color: var(--bs-gray-800);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 16.8px */
}
.sapp-tree-popup-btn {
  color: var(--bs-text-gray-900);
  &:hover {
    color: var(--bs-text-primary);
  }
}
.sapp-item-tree {
  border-radius: 5px;
}

@media (min-width: 1000px) and (max-width: 1024px) {
  .sapp-blank-content {
    min-height: 80vh;
  }
}

.sapp-title-step {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #99a1b7;
  margin-left: 8px;
  font-family: Inter;
}

.sapp-title-step.active {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffb800;
  margin-left: 8px;
  font-family: Inter;
}

.processbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Adjust if necessary */
  z-index: 1000; /* Make sure it's above other content */
  box-shadow: 0px 4px 8px 0px #0000000f;
  // box-shadow: 0px 0px 4px 0px #0000000a;
}

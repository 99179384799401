@import '../../../_metronic/assets/sass/init';

.min-100 {
  min-width: 100%;
}
.sapp-button-dropdown {
  .dropdown-menu {
    padding: 0;
  }
}

.sapp-h-45px {
  height: 45px !important;
}

// .sapp-h-40px {
//   & i {
//     font-size: 14px !important;
//     padding-right: 0px !important;
//     margin-right: 4px !important;
//   }
// }

.sapp-h-50px {
  height: 50px;
}

.sapp-h-30px {
  height: 30px !important;
  font-weight: 600;
  line-height: 12px;
  font-size: 12px;
  padding: 9px 12px !important;

  & i {
    font-size: 12px !important;
    padding-right: 0px !important;
    margin-right: 4px !important;
  }
}

.btn-light.sapp-h-30px {
  padding: 9px 12px !important;
}

.btn-loading {
  color: $alert-primary !important;
  background: $alert-primary-light !important;
  pointer-events: none;

  & i {
    color: $alert-primary !important;
  }
}

.sapp-d-content {
  display: contents;
}

.sapp-btn--padding.btn-light {
  color: var(--bs-gray-800);
}

.btn-primary.sapp-h-40,
.btn-light.sapp-h-40 {
  font-size: 14px;
  color: white;
  font-weight: 500;
  font-family: 'Inter';
  line-height: initial;
}

.bg-light-warning.btn-primary.sapp-h-45px,
.bg-light-warning.btn-primary.sapp-h-45px,
.btn-light-primary.btn-primary.sapp-h-45px {
  color: $primary;
}

.btn-primary.sapp-h-45px,
.btn-light.sapp-h-45px {
  font-size: 14px;
  color: $danger-inverse;
  font-weight: 500;
  // line-height: 14px;
}

.btn-light {
  background-color: var(--bs-gray-200) !important;
  color: var(--bs-gray-800) !important;
}

.btn-sapp-icon.btn {
  font-size: 14px;
  font-weight: 500;
  // line-height: 16px;
  background-color: var(--bs-gray-100);
  color: var(--bs-gray-800);
  font-family: Inter;
}

.btn.btn-sapp-icon i {
  color: var(--bs-gray-800);
}

.btn.btn-sapp-icon:hover:not(.btn-active) {
  background-color: var(--bs-primary-active);
}

.btn.btn-icon--custom:hover {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.contract path {
  transition: fill 0.3s ease;
}

.contract:hover path {
  fill: var(--bs-primary); /* Yellow color on hover */
}

.btn.btn-sapp-filter:hover i {
  color: white !important;
}

.btn.btn-icon--custom:hover:not(.btn-active) {
  background-color: var(--bs-primary) !important;
}

.text-fs-12 {
  font-size: 12px;
}

.line-heigh-12 {
  line-height: 12px;
}

.font-weight-600 {
  font-weight: 600;
}

.text-primary-placeholder {
  color: $primary-placeholder !important;
}

.loader {
  animation: rotation 3s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.min-h-45px {
  min-height: 45px;
}

.min-h-40px {
  min-height: 40px;
}

.custom_modal_header {
  border: none;
}
.add_question_video_tab_group {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;

  .tab_btn {
    padding: 20px;
    cursor: pointer;
    position: relative;
    &.active:after {
      content: '';
      height: 1px;
      width: 100%;
      bottom: -3px;
      left: 0;
      transform: translate(0%, -50%);
      // top:0;
      position: absolute;
      // z-index: 1000000000;
      border-bottom: 3px var(--bs-primary) solid;
    }
  }
}

.body_modal {
  padding: 0;
  .topBody_container {
    display: flex;
    justify-content: flex-end;
    // padding:10px;
    align-items: center;
    .btn_save_video_question {
      max-height: 44px;
    }
  }
  .body_modal_content {
    padding: 10px;
    margin-top: 5px;
    border-top: 1px solid gainsboro;

    select {
      appearance: none;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Chrome and Safari */
      border: 1px solid gainsboro;
      padding: 3px;
      border-radius: 4px;
    }
    .menuTab_container {
      display: flex;
      column-gap: 20px;
      align-items: center;
      .menuName_input {
        border-radius: 4px;
        border: 1px solid;
        border-color: gainsboro;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

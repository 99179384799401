@import '../../../../_metronic/assets/sass/init';

.sapp-fs-20px {
  font-size: 20px;
}

.min-width-250px {
  min-width: 250px;
}

.cache-document {
  margin-top: 32px;
  border: 1px dashed $alert-primary;
  border-spacing: 61px;
  background: $alert-primary-light;
  border-radius: 7px;
  padding: 14px 16px;
}

.cache-document-icon {
  color: $alert-primary;
  font-size: 28px !important;
}

.cache-document-text {
  margin-bottom: 0px;
  margin-left: 16px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
  color: $primary-placeholder;
}

.cache-document-link {
  color: $alert-primary;
}

.card .card-header.create-shop-header {
  min-height: auto;

  .card-title {
    padding: 24px 0;
    margin: 0;
  }
}

.create-shop-root {
  .card-body {
    background-color: white;
    padding: 27px 32px 32px 32px;
    margin: 0;
  }
  .card-footer {
    padding: 16px 32px;
  }
}

.default,
.success,
.error {
  width: 100%;
  height: 100%;
}

.sapp-radio input[type='radio']:checked {
  border-color: transparent;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-width: 0px;
  width: 100%;
  height: 100%;
}

.sapp-radio input[type='radio'].error:checked {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23D35563' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='11.25' fill='white'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='6'%3E%3C/circle%3E%3C/svg%3E");
}

.sapp-radio input[type='radio'].success:checked {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23008000' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='11.25' fill='white'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='6'%3E%3C/circle%3E%3C/svg%3E");
}

.sapp-radio input[type='radio'].default:checked {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23ffb800' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='11.25' fill='white'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='6'%3E%3C/circle%3E%3C/svg%3E");
}

.sapp-radio input[type='radio'] {
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  --tw-shadow: 0 0 #0000;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23A1A1A1' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='11.25' fill='white'%3E%3C/circle%3E%3C/svg%3E");
}

.sapp-label-radio {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.sapp-radio {
  display: inline-block;
  flex: none;
}
